import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Imprint = () => {
  const data = useStaticQuery(graphql`
    query Imprint {
      prismicImprintpage {
        data {
          link {
            html
          }
          paragraph_1 {
            html
          }
          paragraph_2 {
            html
          }
          paragraph_3 {
            html
          }
          paragraph_4 {
            html
          }
          paragraph_5 {
            html
          }
          paragraph_6 {
            html
          }
          sub_title_1 {
            text
          }
          sub_title_3 {
            text
          }
          sub_title_2 {
            text
          }
          sub_title_4 {
            text
          }
          sub_title_5 {
            text
          }
          sub_title_6 {
            text
          }
          title {
            text
          }
        }
      }
    }
  `)

  return (
    <div className="w-10/12 p-4 mx-auto text-center">
      <h1 className="p-4 font-serif text-3xl font-bold">
        {data.prismicImprintpage.data.title.text}
      </h1>
      <h2 className="p-3 font-serif text-lg font-bold">
        {data.prismicImprintpage.data.sub_title_1.text}
      </h2>

      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicImprintpage.data.paragraph_1.html,
        }}
      />
      <h2 className="p-3 font-serif text-lg font-bold">
        {data.prismicImprintpage.data.sub_title_2.text}
      </h2>
      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicImprintpage.data.paragraph_2.html,
        }}
      />
      <h2 className="p-3 font-serif text-lg font-bold">
        {data.prismicImprintpage.data.sub_title_3.text}
      </h2>
      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicImprintpage.data.paragraph_3.html,
        }}
      />
      <h3 className="p-2 font-serif font-bold">
        {data.prismicImprintpage.data.sub_title_4.text}
      </h3>
      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicImprintpage.data.paragraph_4.html,
        }}
      />
      <h3 className="p-2 font-serif font-bold">
        {data.prismicImprintpage.data.sub_title_5.text}
      </h3>
      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicImprintpage.data.paragraph_5.html,
        }}
      />
      <h3 className="p-2 font-serif font-bold">
        {data.prismicImprintpage.data.sub_title_6.text}
      </h3>
      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicImprintpage.data.paragraph_6.html,
        }}
      />
      <div
        className="p-2"
        dangerouslySetInnerHTML={{
          __html: data.prismicImprintpage.data.link.html,
        }}
      />
    </div>
  )
}

export default Imprint
