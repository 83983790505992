import React from "react"
import SEO from "../components/Helmet/SEO"
import Imprint from "../components/Imprint/Imprint"
import PageLayout from "../components/PageLayout/PageLayout"

const Contact = () => {
  return (
    <>
      <SEO title="Impressum" />
      <PageLayout>
        <Imprint />
      </PageLayout>
    </>
  )
}

export default Contact
